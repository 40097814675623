<template>
  <div>
    <bg-header-container />

    <b-card no-body>

      <!-- Loading -->
      <b-card-body v-if="isLoading" class="d-flex p-5 flex-column align-items-center">
        <b-spinner variant="primary" class="mx-auto" />
      </b-card-body>

      <b-card-body
        v-if="!isLoading"
        class="d-flex p-2 flex-column align-items-center"
      >
        <!-- Coupon not applied, show form -->
        <div v-if="!discountSuccess">
          <!-- We have the data for the coupon and the organization -->
          <div v-if="coupon">
            <h1 v-if="coupon.discountPercent" class=" mt-3 text-primary font-large-4">
              -{{ coupon.discountPercent }}%
            </h1>
            <h1 v-else class=" mt-3 text-primary font-large-4">
              {{ translateTranslationTable(locale, coupon.name) }}
            </h1>
            <h4 class="mt-1 text-primary" style="margin-bottom: 1rem;">
              ORG: {{ organizationName }}
            </h4>

            <validation-observer #default="{ handleSubmit }" ref="refFormObserver" class="w-100 mt-3">
              <b-form class="d-flex flex-column" @submit.prevent="handleSubmit(onSubmit)">
                <!-- Price -->
                <validation-provider #default="validationContext" name=" " rules="required">
                  <b-form-group label-for="price" :label="$t('coupons.import') + ' (€)'">
                    <b-form-input
                      id="price"
                      v-model="form.price"
                      type="number"
                      required
                      rules="required"
                      step="0.01"
                      name=" "
                      :placeholder="$t('coupons.import-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- comerç code -->
                <validation-provider
                  #default="validationContext"
                  name=" "
                  rules="required|min:5"
                >
                  <b-form-group label-for="code" :label="$t('coupons.code')">
                    <b-form-input
                      id="code"
                      v-model="form.verificationCode"
                      type="password"
                      :placeholder="$t('coupons.code-placeholder')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback class="text-danger">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <b-alert
                  v-if="invalidOrganizationKey"
                  show
                  variant="danger"
                  class="m-0 error-notification"
                >
                  {{ $t('form.actions.general-error') }}
                </b-alert>

                <small class="text-muted">
                  {{ $t('coupons.policy') }}
                </small>
                <b-button
                  type="submit"
                  variant="primary"
                  class="my-2 button-send mx-auto"
                  :disabled="(!form.price && !form.commercialCode) || isSending"
                >
                  <span v-if="isSending" class="d-flex align-items-center justify-content-center m-0">
                    <b-spinner
                      class="mr-1"
                      small
                      variant="white"
                      label="Loading..."
                    />
                    {{ $t('form.actions.saving') }}
                  </span>
                  <span v-else>
                    {{ $t('form.actions.save') }}
                  </span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <!-- No coupon found -->
          <div v-else>
            No coupon found...
          </div>
        </div>

        <!-- Coupon applied, show success -->
        <div v-else>
          <!-- There is a discount -->
          <div v-if="discount">
            <div class="d-flex flex-column align-items-center justify-content-center mt-3">
              <b-icon-check-circle variant="primary" font-scale="8" class="mb-2 text-center" />

              <h4 class="mt-2 mb-75 text-primary" style="margin-bottom: 1rem;">
                ORG: {{ organizationName }}
              </h4>

              <h3 class="text-center  mb-25">
                {{ $t('coupons.success-discount') }}
              </h3>
              <p class="text-center mb-2 text-muted">
                {{ $t('coupons.resume') }}
              </p>
            </div>
            <div class=" mb-3">
              <h6 class="text-center">
                {{ $t('coupons.details') }}
              </h6>
              <div class="d-flex flex-column">
                <small class="text-center">
                {{ $t('coupons.import') }}:
                  <span class="text-muted mb-50">
                    {{ this.discount.import.toFixed(2) }} € 
                  </span>
                </small>

                <small class="text-center">
                {{ $t('coupons.percentage') }}

                  <span class="text-muted mb-50">
                    {{ +this.discount.discountPercentage }} % 
                  </span>
                </small>
                <small class="text-center">
                {{ $t('coupons.discount') }} :
                  <span class="text-muted mb-50">
                    {{ +this.discount.discount.toFixed(2) }} € 
                  </span>
                </small>

                <small class="text-center">
                {{ $t('coupons.new-import') }} :
                  <span class="text-muted mb-50">
                    {{ +this.discount.newImport.toFixed(2) }} € 
                  </span>
                </small>
              </div>
            </div>
            <b-button variant="primary mb-2" @click="goToHome">
              {{ $t('coupons.enjoy-community') }}
            </b-button>
          </div>
          <!-- There is NO discount -->
          <div v-else>
            No discount found...
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import BgHeaderContainer from '@core/components/bg/BgHeaderContainer.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { OrganizationListingType } from '@copernicsw/community-common';
import { translateTranslationTable } from '@core/libs/i18n/utils';

export default {
  name: 'CouponForm',
  components: { ValidationObserver, ValidationProvider, BgHeaderContainer },
  data() {
    return {
      isLoading: false,
      isSending: false,
      bannerStyle: {},
      form: {},
      invalidOrganizationKey: false,
      discount: null,
      required,
      min,
      discountSuccess: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },
  computed: {
    locale() {
      return this.$store.getters.currentLocale;
    },
    id() {
      return this.$route.params.id;
    },
    type() {
      return this.$route.query.type;
    },
    typePlural() {
      return this.type + 's';
    },
    organizationKey() {
      return this.$route.query.organizationkey;
    },
    organization() {
      if (this.$store.getters.communitiesOthers[this.typePlural]) {
        return this.$store.getters.communitiesOthers[this.typePlural].unpaginated[0];
      }
      return {};
    },
    organizationName() {
      if(this.organization){
        const orgName = this.organization.name;
        // console.log('orgName', orgName);

        const orgNameTranslated = translateTranslationTable(this.locale, orgName);
        // console.log('orgNameTranslated', orgNameTranslated);

        return orgNameTranslated ?? orgName;
      }
      
      return 'Unknown Organization' + (this.organizationKey ? ' with key: ' + this.organizationKey : '') ;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    coupons() {
      return this.$store.getters.coupons;
    },
    couponsUnpaginated() {
      return this.coupons.unpaginated;
    },
    couponKey() {
      return this.$route.params?.id;
    },
    coupon() {
      console.log('couponKey', this.couponKey);
      // console.log('couponsUnpaginated', this.couponsUnpaginated);
      return this.couponsUnpaginated.find(item => item?.key === this.couponKey);
    },
  },
  async created() {
    await this.getInitialData();
    await this.getInitialOrganization();
  },
  methods: {
    async getInitialData() {
      try {
        await this.$store.dispatch('getItems', {
          itemType: 'coupons',
          key: this.id,
          requestConfig: { modelType: this.type, count: 100 },
        });
      } catch {
        this.notifyError(this.$t('error-message.load-failed'));
      }
    },
    async getInitialOrganization() {
      try {
        await this.$store.dispatch('getItems', {
					itemType: "communities/simply",
					customName: "communitiesOthers",
          storedKey: this.typePlural ?? "organizations",
          communityKey: this.organizationKey,
          forceAPICall: true,
          page: 1,
          perPage: 15,
          requestConfig: { 
            isOrganization: this.type === "organization" ? true : false,
            communityParentSlug: this.communitySlug,
						// orderByDate: -1,
            // listingType: OrganizationListingType.forCommunity, 
            // organizationKey: [this.organizationKey] 
          },
        });
      } catch {
        this.notifyError(this.$t('error-message.load-failed'));
      }
    },

    onSubmit() {
      this.isSending = true;
      this.handleCreateItem();
      this.isSending = false;
    },
    goToHome() {
      return this.$router.replace({ name: 'Resumen', params: { communityId: this.communitySlug } });
    },
    async handleCreateItem() {
      this.isSending = true;
      this.invalidOrganizationKey = false;
      try {
        const response = await this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: 'useCoupon',
            requestConfig: {
              verificationCode: +this.form.verificationCode,
              price: +this.form.price,
              couponKey: this.id,
              modelType: "organization",
              organizationKey: this.organizationKey,
            },
          },
        });
        if (response.status === 200) {
          this.discountSuccess = true;
          this.discount = response.data;
        }
        this.isSending = false;
      } catch (error) {
        this.invalidOrganizationKey = true;
        this.isSending = false;
      }
    },
    translateTranslationTable,
  },
};
</script>

<style lang="scss" scoped>
.discount {
  width: 140px;
  height: 140px;
}
.button-send {
  width: 140px;
}
</style>
